import clsx from "clsx";
import { createStyles, makeStyles } from "@mui/styles";
import {
    Box,
    BoxProps,
    InternalStandardProps,
    List,
    ListItem,
    Theme,
} from "@mui/material";
import { Layout } from "../Layout";
import { AppTypography } from "JS/React/Components/AppTypography";
import { useHistory } from "react-router-dom";
import { useRouting } from "JS/React/Hooks/Routes";
import { clearLocalStorage, isBetaEnvironment } from "JS/Helpers";
import { config } from "JS/Config";
import { useResetGlobalState } from "JS/React/Hooks/Media";
import localforage from "localforage";
import { useEffect, useMemo, useState } from "react";
import { AppConfimationDialog } from "JS/React/Components/AppConfirmationDialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFirebaseLogger } from "JS/React/Hooks/Firebase";
import {
    EventActions,
    EventCategories,
    EventNames,
    FirebaseEventAction,
} from "JS/Models";
import { getDexieConnection } from "JS/Database/Dexie";
import { useSnackbar } from "notistack";
import { AppDivider } from "JS/React/Components/AppDivider";
import { useDeleteDeviceToken } from "JS/React/Hooks/Notification";
import { messaging } from "JS/Helpers/UserMessaging";
import { getLocalFcmToken } from "JS/Helpers/PushNotifications";
import { useTeamInformation, useTokenRetry } from "JS/React/Hooks/Home";
import { useResumeData } from "JS/React/Hooks/Firebase/Resume";
import { useAppSelector } from "JS/Redux/Store";
import { UserRoute } from "../../../Helpers/FycStatusConstants";
import { AppBackdropProgress } from "JS/React/Components/Progress/AppBackdropProgress";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        grey600: {
            color: theme.palette.grey[600],
        },
        listItem: {
            fontWeight: 400,
            display: "flex",
            justifyContent: "flex-start",
        },
        listText: { fontWeight: 400 },
        list: {
            flexGrow: 1,
        },
        listIcon: {
            fontWeight: 400,
            fontSize: "24px",
            marginRight: "5%",
            width: "10%",
        },
        versionText: {
            color: theme.palette.common.black,
        },
        fontSize: {
            fontSize: "28px",
        },
    }),
);

export interface MoreMenuProps extends InternalStandardProps<BoxProps> {}

export const MoreMenu = (props: MoreMenuProps) => {
    const classes = useStyles(props);
    const { className, ...rest } = props;

    const history = useHistory();
    const { linkProvider } = useRouting();
    const { resetReduxState } = useResetGlobalState();
    const { enqueueSnackbar } = useSnackbar();

    const [isOpenConfirmDailog, setIsOpenConfirmDailog] = useState(false);
    const [logoutLoader, setLogoutLoader] = useState(false);

    const { logFirebaseEvent } = useFirebaseLogger();

    const { deleteDeviceToken } = useDeleteDeviceToken();
    const { delTokenRetry, decrementTokenRetry } = useTokenRetry();
    const {
        logAudioResume,
        logVideoResume,
        logPlaylistResume,
        logAudioBundleResume,
    } = useResumeData();
    const memberId = config.user.memberId;

    const { refetch, loading: loadingTeamInfo } = useTeamInformation(true);

    const userTeamInformation = useAppSelector(
        (state) => state.home.userTeamInformation,
    );

    useEffect(() => {
        if (!userTeamInformation?.activeSubscriptions?.length) refetch();
    }, [userTeamInformation]);

    const isApprenticeSubcriber = useMemo(
        () =>
            !!userTeamInformation?.activeSubscriptions?.find(
                (item) => item.subscriptionName === "Apprentice",
            ),
        [userTeamInformation],
    );

    const deleteFcmToken = (): Promise<void> => {
        return new Promise(async (res, rej) => {
            const token = getLocalFcmToken();
            if (token) {
                const resp = await deleteDeviceToken(token);
                if (resp.status) {
                    res();
                } else {
                    rej("error");
                }
            } else {
                res();
            }
        });
    };

    const logout = async () => {
        try {
            setLogoutLoader(true);
            try {
                await deleteFcmToken();
            } catch (err) {
                if (delTokenRetry > 0) {
                    enqueueSnackbar(messaging.logout.failed, {
                        variant: "error",
                    });
                    setLogoutLoader(false);
                    decrementTokenRetry();
                    throw err;
                }
            }

            try {
                await logAudioResume(memberId);
                await logVideoResume(memberId);
                await logPlaylistResume(memberId);
                await logAudioBundleResume(memberId);
            } catch (error) {
                throw error;
            }

            resetReduxState();
            clearLocalStorage();
            localforage.clear();

            const action: FirebaseEventAction = {
                action: EventActions.LOGOUT,
                category: EventCategories.GENERAL,
            };

            logFirebaseEvent(EventNames.LOGGED_OUT, action);

            setTimeout(() => {
                window.location.reload();
            }, 2000);
        } catch (err) {}
    };
    const handleToggleConfirmDialog = () => {
        setIsOpenConfirmDailog(!isOpenConfirmDailog);
    };

    const downloadQueue = localStorage.getItem("downloadQueue")
        ? JSON.parse(localStorage.getItem("downloadQueue"))
        : [];

    const getDbFilled = async () => {
        try {
            const db = getDexieConnection();
            const videos = await db.videos.toArray();
            const audios = await db.audios.toArray();

            const sizes = [...videos, ...audios].map((d) => d.blob.size);
            const sum = sizes.reduce((a, b) => a + b);

            enqueueSnackbar(`Total db used ${(sum * 0.000001).toFixed(2)} MB`, {
                variant: "info",
            });
        } catch (err) {
            console.log("ignore this err");
        }
    };

    const handleNavigateToQueue = () =>
        history.push(linkProvider.react.downloadingQueue().index());

    return (
        <Layout>
            <Box
                {...rest}
                className={clsx(className, classes.root)}
                display={"flex"}
                flexDirection={"column"}
                height={"100%"}
            >
                <List className={classes.list}>
                    <ListItem
                        button
                        onClick={() =>
                            history.push(linkProvider.react.gifts().index())
                        }
                        className={clsx(classes.listItem)}
                    >
                        <FontAwesomeIcon
                            className={clsx(classes.grey600, classes.listIcon)}
                            icon={["fas", "gift"]}
                        />
                        <AppTypography
                            className={clsx(classes.grey600, classes.listText)}
                            variant="h6"
                        >
                            Gifts
                        </AppTypography>
                    </ListItem>
                    <AppDivider />
                    <ListItem
                        button
                        onClick={() =>
                            history.push(
                                linkProvider.react.giftDashboard().index(),
                            )
                        }
                        className={clsx(classes.listItem)}
                    >
                        <FontAwesomeIcon
                            className={clsx(classes.grey600, classes.listIcon)}
                            icon={["fas", "dashboard"]}
                        />
                        <AppTypography
                            className={clsx(classes.grey600, classes.listText)}
                            variant="h6"
                        >
                            Gift Dashboard
                        </AppTypography>
                    </ListItem>
                    <AppDivider />
                    <ListItem
                        button
                        onClick={() =>
                            history.push(linkProvider.react.documents().index())
                        }
                        className={clsx(classes.listItem)}
                    >
                        <FontAwesomeIcon
                            className={clsx(classes.grey600, classes.listIcon)}
                            icon={["far", "clipboard"]}
                        />
                        <AppTypography
                            className={clsx(classes.grey600, classes.listText)}
                            variant="h6"
                        >
                            Documents
                        </AppTypography>
                    </ListItem>
                    <AppDivider />
                    <ListItem
                        button
                        onClick={() =>
                            history.push(linkProvider.react.favorites().index())
                        }
                        className={clsx(classes.listItem)}
                    >
                        <FontAwesomeIcon
                            className={clsx(classes.grey600, classes.listIcon)}
                            icon={["fas", "star"]}
                        />
                        <AppTypography
                            className={clsx(classes.grey600, classes.listText)}
                            variant="h6"
                        >
                            Favorites
                        </AppTypography>
                    </ListItem>
                    <AppDivider />
                    <ListItem
                        button
                        onClick={() =>
                            history.push(linkProvider.react.events().index())
                        }
                        className={clsx(classes.listItem)}
                    >
                        <FontAwesomeIcon
                            className={clsx(classes.grey600, classes.listIcon)}
                            icon={["far", "address-card"]}
                        />
                        <AppTypography
                            className={clsx(classes.grey600, classes.listText)}
                            variant="h6"
                        >
                            My Event Registrations
                        </AppTypography>
                    </ListItem>
                    <AppDivider />
                    {isApprenticeSubcriber && (
                        <>
                            <ListItem
                                button
                                onClick={() =>
                                    history.push(
                                        linkProvider.react
                                            .fycStatus()
                                            .index(UserRoute.PRIMARY_USER),
                                    )
                                }
                                className={clsx(classes.listItem)}
                            >
                                <FontAwesomeIcon
                                    className={clsx(
                                        classes.grey600,
                                        classes.listIcon,
                                        classes.fontSize,
                                    )}
                                    icon={["far", "id-badge"]}
                                />
                                <AppTypography
                                    className={clsx(
                                        classes.grey600,
                                        classes.listText,
                                    )}
                                    variant="h6"
                                >
                                    My FCY Status
                                </AppTypography>
                            </ListItem>
                            <AppDivider />
                        </>
                    )}
                    <ListItem
                        button
                        onClick={() =>
                            history.push(linkProvider.react.playlist().index())
                        }
                        className={clsx(classes.listItem)}
                    >
                        <FontAwesomeIcon
                            className={clsx(classes.grey600, classes.listIcon)}
                            icon={["fas", "music"]}
                        />
                        <AppTypography
                            className={clsx(classes.grey600, classes.listText)}
                            variant="h6"
                        >
                            Audio Playlists
                        </AppTypography>
                    </ListItem>
                    <AppDivider />
                    <ListItem
                        button
                        onClick={() =>
                            history.push(linkProvider.react.webcast().index())
                        }
                        className={clsx(classes.listItem)}
                    >
                        <FontAwesomeIcon
                            className={clsx(classes.grey600, classes.listIcon)}
                            icon={["fas", "earth-americas"]}
                        />
                        <AppTypography
                            className={clsx(classes.grey600, classes.listText)}
                            variant="h6"
                        >
                            Webcast
                        </AppTypography>
                    </ListItem>
                    <AppDivider />
                    <ListItem
                        button
                        onClick={() =>
                            history.push(linkProvider.react.appInfo().index())
                        }
                        className={clsx(classes.listItem)}
                    >
                        <FontAwesomeIcon
                            className={clsx(classes.grey600, classes.listIcon)}
                            icon={["far", "file-lines"]}
                        />
                        <AppTypography
                            className={clsx(classes.grey600, classes.listText)}
                            variant="h6"
                        >
                            App Info
                        </AppTypography>
                    </ListItem>
                    <AppDivider />
                    <ListItem
                        button
                        onClick={handleNavigateToQueue}
                        className={clsx(classes.listItem)}
                    >
                        <FontAwesomeIcon
                            className={clsx(classes.grey600, classes.listIcon)}
                            icon={["fas", "file-arrow-down"]}
                        />
                        <AppTypography
                            className={clsx(classes.grey600, classes.listText)}
                            variant="h6"
                        >
                            Downloading Queue
                        </AppTypography>
                    </ListItem>
                    <AppDivider />
                    <ListItem
                        button
                        onClick={() =>
                            history.push(linkProvider.react.feedback().index())
                        }
                        className={clsx(classes.listItem)}
                    >
                        <FontAwesomeIcon
                            className={clsx(classes.grey600, classes.listIcon)}
                            icon={["fas", "message"]}
                        />
                        <AppTypography
                            className={clsx(classes.grey600, classes.listText)}
                            variant="h6"
                        >
                            Feedback
                        </AppTypography>
                    </ListItem>
                    <AppDivider />
                    <ListItem
                        button
                        onClick={() =>
                            history.push(
                                linkProvider.react.appSettings().index(),
                            )
                        }
                        className={clsx(classes.listItem)}
                    >
                        <FontAwesomeIcon
                            className={clsx(classes.grey600, classes.listIcon)}
                            icon={["fas", "cogs"]}
                        />
                        <AppTypography
                            className={clsx(classes.grey600, classes.listText)}
                            variant="h6"
                        >
                            Settings
                        </AppTypography>
                    </ListItem>
                    <AppDivider />
                    <ListItem
                        button
                        onClick={handleToggleConfirmDialog}
                        className={clsx(classes.listItem)}
                    >
                        <FontAwesomeIcon
                            className={clsx(classes.grey600, classes.listIcon)}
                            icon={["fas", "power-off"]}
                        />
                        <AppTypography
                            className={clsx(classes.grey600, classes.listText)}
                            variant="h6"
                        >
                            Logout
                        </AppTypography>
                    </ListItem>

                    <AppDivider />
                </List>
                <AppTypography
                    textAlign={"right"}
                    variant="caption"
                    className={classes.versionText}
                >
                    Version:{" "}
                    {isBetaEnvironment()
                        ? config.betaAppVersion
                        : config.appVersion}
                </AppTypography>
                {isOpenConfirmDailog && (
                    <AppConfimationDialog
                        open={isOpenConfirmDailog}
                        onConfirm={logout}
                        btnConfirmLoader={logoutLoader}
                        onClose={handleToggleConfirmDialog}
                        popupMainText={
                            downloadQueue?.length > 0
                                ? `Downloading is in-progress in Queue. Downloading Queue data will be lost. Are you sure you want to log out?`
                                : "Are you sure you want to logout?"
                        }
                        confirmText="Logout"
                        cancelButtonText="Cancel"
                        showConfirmFirst={true}
                    />
                )}
                {loadingTeamInfo && (
                    <AppBackdropProgress
                        open={loadingTeamInfo}
                        backdropText={messaging.loader.loadingSession}
                    />
                )}
            </Box>
        </Layout>
    );
};
